<template>
  <v-app-bar id="core-toolbar" app flat style="background: #eee;">

    <v-app-bar-nav-icon @click.stop="$emit('toggleNavDrawer')" />

    <v-toolbar-title class="tertiary--text font-weight-light">{{title}}</v-toolbar-title>

    <v-spacer />

    <v-menu>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" class="ml-2" min-width="0" text>
          <v-icon class="mr-1">mdi-account</v-icon>{{userInitials}}
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title @click="logout()">Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

  </v-app-bar>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'AppBar',

  data: () => ({
    title: null,
    responsive: false,
    logoutEndpoint: '/a/logout'
  }),

  computed: {
    userInitials () {
      return auth.user.initials
    },
  },

  watch: {
    '$route' (val) {
      this.title = val.name
    },
  },

  components: {
  },

  methods: {
    logout: function () {
      // make post call to remove session from server
      this.$http.post(this.logoutEndpoint).then(resp => {

        // clear API error if any
        this.$store.commit('system/setAPIError', null)

        this.$router.push('/login')
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.logoutEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => {
        // reset auth object and remove token from storage
        auth.logout()

        // google signout
        /*var auth2 = window.gapi.auth2.getAuthInstance();
        auth2.signOut()
        .catch(errResp => {
          console.log(errResp);
        })*/
      })
    },
  },
}
</script>
